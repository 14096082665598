import React from "react";
import styled from "styled-components";

let p5;

const P5Wrapper = styled.div`

`;

let windowWidth;

export default class P5Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: ""
    };
  }

  componentDidMount() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });

    p5 = require("p5"); // Can't import because interferes with gatsby build, see https://github.com/NeroCor/react-p5-wrapper/issues/2
    this.canvas = new p5(this.props.sketch, this.wrapper);

    if (this.canvas.myCustomRedrawAccordingToNewPropsHandler) {
      this.canvas.myCustomRedrawAccordingToNewPropsHandler(this.props, windowWidth);
    }
  }

  componentDidUpdate(newprops) {
    windowWidth = this.state.width;

    if (this.props.sketch !== newprops.sketch) {
      this.canvas.remove();
      this.canvas = new p5(newprops.sketch, this.wrapper);
    }
    if (this.canvas.myCustomRedrawAccordingToNewPropsHandler) {
      this.canvas.myCustomRedrawAccordingToNewPropsHandler(
        newprops,
        windowWidth,
      );
    }
  }

  componentWillUnmount() {
    this.canvas.remove();
  }

  render() {
    return <P5Wrapper ref={wrapper => (this.wrapper = wrapper)} />;
  }
}
